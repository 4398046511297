exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-clients-js": () => import("./../../../src/pages/clients.js" /* webpackChunkName: "component---src-pages-clients-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-team-js": () => import("./../../../src/pages/our-team.js" /* webpackChunkName: "component---src-pages-our-team-js" */),
  "component---src-pages-sectors-index-js": () => import("./../../../src/pages/sectors/index.js" /* webpackChunkName: "component---src-pages-sectors-index-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */),
  "component---src-template-all-projects-template-js": () => import("./../../../src/template/all-projects-template.js" /* webpackChunkName: "component---src-template-all-projects-template-js" */),
  "component---src-template-project-template-js": () => import("./../../../src/template/project-template.js" /* webpackChunkName: "component---src-template-project-template-js" */),
  "component---src-template-sector-template-js": () => import("./../../../src/template/sector-template.js" /* webpackChunkName: "component---src-template-sector-template-js" */)
}

